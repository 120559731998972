<div class="row" *ngIf="layout === 'stacked'; else horizontalLayout">
    <div class="col-12 col-md p-7 pt-1">
        <div class="d-flex">
            <div class="width-wrap">
                <ng-container *ngTemplateOutlet="playPauseStopButton"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="description"></ng-container>
        </div>
        <div class="ms-md-18">
            <div class="separator mt-8 mb-6"></div>
            <ng-container *ngTemplateOutlet="timerFields"></ng-container>
        </div>
    </div>
    <div class="col col-md-auto mb-7 mb-md-0 p-7 pt-1 text-center">
        <app-timer-task-progress></app-timer-task-progress>
    </div>
</div>

<ng-template #horizontalLayout>
    <div class="d-flex align-items-center py-4 px-5 gap-5 horizontal">
        <ng-container *ngTemplateOutlet="playPauseStopButton"></ng-container>
        <div class="duration">{{ timerService.secondsElapsed$ | async | timerDisplayFormat: "colon" }}</div>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="timerFields"></ng-container>
    </div>
</ng-template>

<ng-template #playPauseStopButton>
    <app-play-pause-stop-button
        [enableHover]="layout !== 'horizontal'"
        [isRunning]="this.timerService.isRunning$ | async"
        (playClick)="requestTimerStart()"
        (stopClick)="stopTimer()"
        (cancelClick)="cancelTimer()"
        [ngClass]="{ glowing: (timerService.isWaiting$ | async) }"
    ></app-play-pause-stop-button>
</ng-template>

<ng-template #description>
    <app-time-entry-description
        [ngClass]="{ 'flex-grow-1': layout === 'stacked', 'ms-10': layout === 'horizontal' }"
        [inputClass]="layout === 'stacked' ? 'form-control m-input' : 'outline outline-lg'"
        placeholder="Enter description here"
        [parentForm]="parentForm"
        (parseStart)="onDescriptionParseStart()"
        (parseComplete)="onDescriptionParsed($event)"
        (keyup.enter)="requestTimerStart()"
    ></app-time-entry-description>
</ng-template>

<ng-template #timerFields>
    <app-timer-field-details
        [parentForm]="parentForm"
        [inputClass]="layout === 'horizontal' ? 'outline outline-lg' : 'outline'"
        [layout]="layout"
        [selectedProject]="projectControl.value"
    ></app-timer-field-details>
</ng-template>
