import { CommonModule } from "@angular/common";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DurationUtils } from "@shared/helpers/duration-utils";
import { UtilsModule } from "@shared/utils/utils.module";
import { ProgressCircle } from "@timer/models/progress-circle";
import { TimerService } from "@timer/services/timer.service";
import { DateTime, Duration } from "luxon";
import { NgCircleProgressModule } from "ng-circle-progress";
import { BehaviorSubject, combineLatest, filter, Subject, takeUntil } from "rxjs";

@Component({
    standalone: true,
    selector: "app-timer-task-progress",
    templateUrl: "./timer-task-progress.component.html",
    styleUrls: ["./timer-task-progress.component.scss"],
    imports: [CommonModule, NgCircleProgressModule, UtilsModule]
})
export class TimerTaskProgressComponent extends AppComponentBase implements OnInit, OnDestroy {
    private readonly runningTimerUnderEstimate: ProgressCircle = {
        percent: 0,
        color: "#ADE2FF"
    };
    private readonly loggedTimeUnderEstimate: ProgressCircle = {
        percent: 0,
        color: "#33B6FF"
    };
    private readonly runningTimerOverEstimate: ProgressCircle = {
        percent: 0,
        color: "#FF5D5D"
    };
    private readonly loggedTimeOverEstimate: ProgressCircle = {
        percent: 0,
        color: "#F44242"
    };

    readonly progressCircles: ProgressCircle[] = [
        this.runningTimerUnderEstimate,
        this.loggedTimeUnderEstimate,
        this.runningTimerOverEstimate,
        this.loggedTimeOverEstimate
    ];

    readonly duration$ = new BehaviorSubject<number>(0);
    readonly durationIsFocused$ = new BehaviorSubject<boolean>(false);

    totalSeconds = 0;

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        public timerService: TimerService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        combineLatest([
            this.timerService.secondsElapsed$,
            this.timerService.totalTimeLoggedToIssue$,
            this.timerService.originalEstimate$
        ])
            .pipe(takeUntil(this.unsubscribeSubject$))
            .subscribe({
                next: ([elapsedSeconds, totalLoggedSeconds, estimate]) => {
                    if (elapsedSeconds !== undefined && totalLoggedSeconds !== undefined && estimate) {
                        this.totalSeconds = totalLoggedSeconds;
                        this.updateProgressCircles(elapsedSeconds, estimate);
                    } else {
                        this.totalSeconds = elapsedSeconds ?? 0;
                        this.runningTimerUnderEstimate.percent = 0;
                        this.loggedTimeUnderEstimate.percent = 0;
                        this.runningTimerOverEstimate.percent = 0;
                        this.loggedTimeOverEstimate.percent = 0;
                    }
                }
            });

        combineLatest([this.timerService.secondsElapsed$, this.durationIsFocused$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([, durationIsFocused]) => !durationIsFocused)
            )
            .subscribe({ next: ([secondsElapsed]) => this.duration$.next(secondsElapsed) });
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    private updateProgressCircles(elapsedSeconds: number, estimate: number): void {
        const runningTotalPercent = (this.totalSeconds / estimate) * 100;
        const loggedTotalPercent = ((this.totalSeconds - elapsedSeconds) / estimate) * 100;

        this.runningTimerUnderEstimate.percent = runningTotalPercent > 100 ? 100 : runningTotalPercent;
        this.loggedTimeUnderEstimate.percent = loggedTotalPercent > 100 ? 100 : loggedTotalPercent;
        this.runningTimerOverEstimate.percent = runningTotalPercent > 100 ? runningTotalPercent % 100 : 0;

        if (loggedTotalPercent >= 200 || runningTotalPercent > 200) {
            this.loggedTimeOverEstimate.percent = 100;
        } else if (loggedTotalPercent > 100) {
            this.loggedTimeOverEstimate.percent = loggedTotalPercent % 100;
        } else {
            this.loggedTimeOverEstimate.percent = 0;
        }
    }

    async updateDuration(input: string): Promise<void> {
        const lastDuration = Duration.fromObject({ seconds: Math.floor(this.duration$.getValue()) });
        this.durationIsFocused$.next(false);

        const trimmedInput = input.trim();
        if (!trimmedInput.length) {
            return;
        }

        const newDuration = DurationUtils.parseDuration(trimmedInput);
        if (lastDuration.minus(newDuration).as("seconds") === 0) {
            return;
        }

        // VOQ-1716: This is just a control and doesn't 'own' the context of the running timer. Should probably emit value change to its parent. This would likely involve refactoring the whole component.
        try {
            await this.timerService.adjustStartTime(DateTime.local().minus(newDuration).toJSDate());
        } catch (error) {
            abp.notify.error(error);
        }
    }
}
