<div class="circle-progress-wrapper d-flex align-items-center justify-content-center m-auto position-relative">
    <circle-progress
        *ngFor="let progressCircle of progressCircles; let first = first"
        [percent]="progressCircle.percent"
        [radius]="69"
        [space]="-21"
        [outerStrokeWidth]="21"
        [innerStrokeWidth]="21"
        [outerStrokeColor]="progressCircle.color"
        [outerStrokeGradient]="false"
        [outerStrokeLinecap]="'butt'"
        [innerStrokeColor]="first ? '#F2F2F2' : '#00000000'"
        [animation]="false"
        [showTitle]="false"
        [animateTitle]="false"
        [showSubtitle]="false"
        [animationDuration]="0"
        [startFromZero]="true"
        [showZeroOuterStroke]="false"
        [lazy]="false"
    ></circle-progress>
    <div class="progress-input-wrapper">
        <input
            #durationInput
            class="progress-input px-3 text-center"
            pInputText
            type="text"
            value="{{ duration$ | async | timerDisplayFormat: 'colon' }}"
            (keydown.enter)="durationInput.blur()"
            (keyup.esc)="durationInput.blur()"
            (focusout)="updateDuration(durationInput.value)"
            (focus)="durationInput.select(); durationIsFocused$.next(true)"
        />
        <span class="estimate-content">
            <span>T: {{ totalSeconds | timerDisplayFormat: "component" }}</span>
            <span *ngIf="timerService.originalEstimate$ | async as originalEstimate">
                / {{ originalEstimate | timerDisplayFormat: "component" }}
            </span>
        </span>
    </div>
</div>
