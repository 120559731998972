<div
    *ngIf="layout === 'stacked' || layout === 'vertical'; else horizontalLayout"
    class="timer-field-details-wrapper"
    [formGroup]="parentForm"
>
    <div class="timer-field-details flex-grow-1">
        <div class="row" [ngClass]="layout === 'vertical' ? 'gy-8' : 'mb-4 gy-4'">
            <div [ngClass]="layout === 'vertical' ? 'col-12' : 'col-6 col-lg-4'">
                <ng-container *ngTemplateOutlet="projectField"></ng-container>
            </div>
            <div [ngClass]="layout === 'vertical' ? 'col-12' : 'col-6 col-lg-3'">
                <ng-container *ngTemplateOutlet="voqServiceField"></ng-container>
            </div>
            <div [ngClass]="layout === 'vertical' ? 'col' : 'col-6 col-lg-4'">
                <ng-container *ngTemplateOutlet="taskField"></ng-container>
            </div>
            <div
                class="col-auto d-flex align-items-center"
                *ngIf="
                    issueControl?.value?.issueKey &&
                        issueControl?.value?.jiraIssueId &&
                        issuesService.jiraIntegrationUrl | async
                "
            >
                <ng-container *ngTemplateOutlet="jiraIssueLink"></ng-container>
            </div>
            <div
                class="d-flex flex-nowrap align-items-center"
                [ngClass]="{
                    'd-none': !selectedProject?.isBillable,
                    'col-auto': layout === 'stacked',
                    'col-12': layout === 'vertical'
                }"
            >
                <ng-container *ngTemplateOutlet="billableField"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #horizontalLayout>
    <div class="d-flex gap-5 align-items-center">
        <ng-container *ngTemplateOutlet="projectField"></ng-container>
        <ng-container *ngTemplateOutlet="voqServiceField"></ng-container>
        <ng-container *ngTemplateOutlet="taskField"></ng-container>
        <div class="d-flex flex-nowrap align-items-center">
            <ng-container *ngTemplateOutlet="billableField"></ng-container>
        </div>
        <div
            *ngIf="
                issueControl?.value?.issueKey && issueControl?.value?.jiraIssueId && issuesService.jiraIntegrationUrl
                    | async
            "
        >
            <ng-container *ngTemplateOutlet="jiraIssueLink"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #projectField>
    <label for="Project" *ngIf="layout === 'vertical'">{{ l("Project") }}*</label>
    <p-dropdown
        [formControl]="projectControl"
        [styleClass]="layout === 'vertical' ? '' : layout === 'horizontal' ? inputClass : 'outline'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'vertical' ? '' : 'outline-panel'"
        id="Project"
        name="Project"
        [options]="projects$ | async"
        optionLabel="name"
        dataKey="id"
        [filter]="true"
        filterBy="filterField"
        placeholder="Project"
        [showClear]="true"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    >
        <ng-template let-item pTemplate="selectedItem">
            {{ item?.name }}
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div>{{ item?.name }}</div>
            <small class="text-light">{{ item?.clientName }}</small>
        </ng-template>
    </p-dropdown>
    <div *ngIf="projectControl.invalid" class="text-danger mt-1 ps-3">Project is required</div>
</ng-template>

<ng-template #voqServiceField>
    <label for="VoqService" *ngIf="layout === 'vertical'">{{ l("VoqService") }}*</label>
    <p-dropdown
        [formControl]="voqServiceControl"
        [styleClass]="layout === 'vertical' ? '' : layout === 'horizontal' ? inputClass : 'outline'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'vertical' ? '' : 'outline-panel'"
        id="VoqService"
        name="VoqService"
        [options]="voqServices$ | async"
        optionLabel="name"
        datakey="id"
        placeholder="Service"
        [showClear]="true"
        [filter]="true"
        filterBy="name"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    ></p-dropdown>
    <div *ngIf="voqServiceControl.invalid" class="text-danger mt-1 ps-3">Service is required</div>
</ng-template>

<ng-template #taskField>
    <label for="Issue" *ngIf="layout === 'vertical'">{{ l("Task") }}</label>
    <p-dropdown
        id="Issue"
        name="Issue"
        [formControl]="issueControl"
        [styleClass]="layout === 'vertical' ? '' : layout === 'horizontal' ? inputClass : 'outline'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'vertical' ? '' : 'outline-panel'"
        [options]="issues$ | async"
        dataKey="issueKey"
        (onFilter)="filterIssues$.next($event?.filter ?? undefined)"
        (onChange)="onIssueChange($event?.value)"
        (onShow)="updateIssues()"
        [showClear]="true"
        [filter]="true"
        [resetFilterOnHide]="true"
        filterBy="issueKey"
        [placeholder]="l('Task')"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    >
        <ng-template let-issue pTemplate="selectedItem">
            {{ issue?.issueKey }}
        </ng-template>
        <ng-template let-issue pTemplate="item">
            <div>{{ issue?.issueKey }}: {{ issue?.title }}</div>
        </ng-template>
    </p-dropdown>
</ng-template>

<ng-template #jiraIssueLink>
    <a [href]="(issuesService.jiraIntegrationUrl | async) + '/browse/' + issueControl?.value?.issueKey" target="_blank">
        <span [inlineSVG]="'/assets/metronic/themes/voqaratheme1/css/timer-share.svg'"></span>
    </a>
</ng-template>

<ng-template #billableField>
    <p-checkbox
        [formControl]="billableControl"
        styleClass="field-checkbox"
        checkboxIcon="check-icon"
        labelStyleClass="checkbox-label"
        name="isBillable"
        id="isBillable"
        inputId="isBillable"
        [binary]="true"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    ></p-checkbox>
    <label for="isBillable" class="billable-checkbox">Billable</label>
</ng-template>
